import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                background: 'FAFBFD',
                primary: '5DA168',
                secondary: 'F8F9FA',
                tertiary: 'FDFDFD',
                contrast: '396AFF'
            },
            dark: {
                primary: '1B396C',
                background: 'FAFBFD',
                secondary: '17191f'
            }
        }
    }
});

import Vue from "vue";

export default {
    namespaced: true,
    state: {
        mieles: []
    },
    mutations: {
        INDEX_MIELES(state){
            Vue.prototype.$axios.get('/api/miel').then(response => {
                if(response.status == 200){
                  state.mieles = response.data;
                }else{
                  console.log(response);
                }
              }).catch(error => {
                console.log(error);
              });
        },
        INSERT_MIEL(state, miel){
            state.mieles.push(miel);
        },
        UPDATE_MIEL(state, miel){
            let index = state.mieles.findIndex(mielObj => mielObj._id == miel._id);
            state.mieles.splice(index, 1, miel);
        },
        DELETE_MIEL(state, _id){
            let index = state.mieles.findIndex(mielObj => mielObj._id == _id);
            state.mieles.splice(index, 1);
        }
    },
    getters: {
        GET_MIELES(state){
            return state.mieles;
        }
    },
    actions: {

    }
}
<template>
<v-app>
    <v-overlay color="white" :value="loading" opacity="1" absolute>
        <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
    <error />
    <snackbar />
    <v-navigation-drawer absolute app color="white" :mini-variant="mini" mini-variant-width="64px" touchless floating permanent v-if="session != null" class="elevation-custom">
        <v-list nav>
            <v-list-item class="py-1">
                <v-list-item-avatar color="primary" class="font-weight-regular white--text">
                    <small>{{session.user.name[0]}}</small>
                </v-list-item-avatar>
                <v-list-item-title></v-list-item-title>
            </v-list-item>
        </v-list>
        <v-list nav>
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item link to="/" active-class="primary--text secondary" v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon small class="mx-1">mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Inicio</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Inicio</span>
            </v-tooltip>
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item to="/floras" active-class="primary--text secondary" v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon small class="mx-1">mdi-flower</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Flora</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Flora</span>
            </v-tooltip>
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item to="/semillas" active-class="primary--text secondary" v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon small class="mx-1">mdi-seed</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Semillas</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Semillas</span>
            </v-tooltip>
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item to="/mieles" active-class="primary--text text--darken-2 secondary" v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon small class="mx-1">mdi-beekeeper</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Mieles</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Mieles</span>
            </v-tooltip>
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item to="/artesanias" active-class="primary--text secondary" v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon small class="mx-1">mdi-image</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Artesanias</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Artesanias</span>
            </v-tooltip>
            <v-list-item to="/usuarios" active-class="primary--text secondary">
                <v-list-item-icon>
                    <v-icon small class="mx-1">mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item>
            <v-list-item to="/configuracion" active-class="primary--text secondary">
                <v-list-item-icon>
                    <v-icon small class="mx-1">mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <v-list nav>
                <profile />
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="logout" active-class="primary--text secondary" v-bind="attrs" v-on="on">
                            <v-list-item-icon>
                                <v-icon small class="mx-1" color="red">mdi-logout</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Cerrar sesión</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>Cerrar sesión</span>
                </v-tooltip>
            </v-list>
        </template>
    </v-navigation-drawer>
    <v-main>
        <router-view />
    </v-main>

</v-app>
</template>

<script>
import profile from "@/components/profile";
import snackbar from "@/components/snackbar";
import error from "@/components/error"
export default {
    name: 'App',
    components: {
        snackbar,
        profile,
        error
    },
    data: () => ({
        mini: true,
        loading: false,
    }),
    beforeMount() {
        this.initialize();
    },
    mounted() {
        
    },
    methods: {
        logout() {
            this.$store.commit('DELETE_SESSION');
            this.$router.push('/login');
        },
        async initialize(){
            this.loading = true;
            this.$store.commit('snackbar/HIDE_SNACKBAR');
            if (this.session != null) {
                this.$axios.defaults.headers.common["token"] = this.session.token;
                await this.$axios.get('/api/session/me').then(response => {
                    if (response.status == 200) {
                        this.$axios.get('/api/user/' + this.session.user._id).then(response => {
                            if (response.status == 200) {
                                this.$store.state.session.user = response.data;
                            }
                        })
                        this.$store.commit('flora/INDEX_FLORAS');
                        this.$store.commit('semilla/INDEX_SEMILLAS');
                        this.$store.commit('miel/INDEX_MIELES');
                        this.$store.commit('artesania/INDEX_ARTESANIAS');
                        this.$store.commit('usuario/INDEX_USUARIOS');
                        if(this.session.user.role == 'ADMIN' || this.session.user.role == 'SUPERADMIN'){
                            this.$store.commit('token/INDEX_TOKENS');
                        }
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('DELETE_SESSION');
                        this.$router.push('/login');
                    }
                }).then(() => {
                    this.loading = false;
                })
            }else{
                this.loading = false;
            }
        }
    },
    computed: {
        session() {
            return this.$store.getters.GET_SESSION;
        },
    }
};
</script>

<style>
.v-navigation-drawer {
    z-index: 1 !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 0px !important;
}

.v-application--is-ltr .v-list-item__avatar:first-child, .v-application--is-rtl .v-list-item__action:last-of-type:not(:only-child), .v-application--is-rtl .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-rtl .v-list-item__icon:last-of-type:not(:only-child) {
    margin-right: 0px !important;
}

a.v-btn.v-size--default {
    height: 38px !important;
}

.slide-fade-enter-active {
    transition: all .2s ease;
}

.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.01s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.v-divider {
    background-color: rgba(0, 0, 0, 0.01) !important;
}

.custom-border {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

body {
    background: #F5F7FB;
}

#app {
    background: #F5F7FB;
    font-family: 'Lato', sans-serif !important;

}

.v-label.v-label--active.theme--light {
    font-weight: 600;
    font-size: 16px;
}

.v-toolbar__title {
    font-weight: 700;
    letter-spacing: .5px;
}

.v-data-table-header>tr {
    height: 56px;
}

.v-data-table-header>tr>th {
    font-size: 13px !important;
}

.v-btn__content {
    letter-spacing: 0px;
    font-weight: 600 !important;
}

.v-data-table-header {
    border-radius: 50px;
}

.v-data-table>.v-data-table__wrapper {
    border-radius: 10px !important;
}

.elevation-custom {
    box-shadow: 1px 2px 10px -7px rgba(0, 0, 0, 0.38) !important;
    -webkit-box-shadow: 1px 2px 10px -7px rgba(0, 0, 0, 0.38) !important;
    -moz-box-shadow: 1px 2px 10px -7px rgba(0, 0, 0, 0.38) !important;
}

.elevation-custom-table {
    box-shadow: 0px 0px 10px -7px rgba(0, 0, 0, 0.38) !important;
    -webkit-box-shadow: 0px 0px 10px -7px rgba(0, 0, 0, 0.38) !important;
    -moz-box-shadow: 0px 0px 10px -7px rgba(0, 0, 0, 0.38) !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09) !important;
}

tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .02);
}

tbody tr:nth-of-type(odd):hover {
    background-color: rgba(0, 100, 0, .08) !important;
}

tbody tr:nth-of-type(even):hover {
    background-color: rgba(0, 100, 0, .08) !important;
}

.scroll {
    overflow-y: scroll !important;
}
</style>

<template>
<div class="home">
    <v-container fluid>
      <h1 class="pl-1 mb-2 grey--text text--darken-3">Resumen de artículos</h1>
        <v-row dense>
            <v-col cols="3">
                <v-card flat>
                    <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="mt-0">Flora</v-list-item-subtitle>
                          <v-list-item-title class="text-h5">
                            {{floras}}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-avatar size="60" color="secondary">
                          <v-icon x-large color="primary">mdi-flower</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card flat>
                    <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="mt-0">Semillas</v-list-item-subtitle>
                          <v-list-item-title class="text-h5">
                            {{semillas}}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-avatar size="60" color="secondary">
                          <v-icon x-large color="primary">mdi-seed</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card flat>
                    <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="mt-0">Mieles</v-list-item-subtitle>
                          <v-list-item-title class="text-h5">
                            {{mieles}}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-avatar size="60" color="secondary">
                          <v-icon x-large color="primary">mdi-beekeeper</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card flat>
                    <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="mt-0">Artesanías</v-list-item-subtitle>
                          <v-list-item-title class="text-h5">
                            {{artesanias}}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-avatar size="60" color="secondary">
                          <v-icon x-large color="primary">mdi-image</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
export default {
    name: 'Home',
    components: {},
    data: () => ({

    }),
    computed: {
        floras() {
          return this.$store.getters['flora/GET_FLORAS'].length
        },
        semillas() {
          return this.$store.getters['semilla/GET_SEMILLAS'].length
        },
        mieles() {
          return this.$store.getters['miel/GET_MIELES'].length
        },
        artesanias() {
          return this.$store.getters['artesania/GET_ARTESANIAS'].length
        }
    }
}
</script>

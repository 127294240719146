import Vue from "vue";

export default {
    namespaced: true,
    state: {
        tokens: []
    },
    mutations: {
        INDEX_TOKENS(state){
            Vue.prototype.$axios.get('/api/token').then(response => {
                if(response.status == 200){
                    state.tokens = response.data;
                }else{
                    console.log(response);
                }
            }).catch(error => {
                console.log(error);
            })
        },
        INSERT_TOKEN(state, token){
            state.tokens.push(token);
        },
        UPDATE_TOKEN(state, token){
            let index = state.tokens.findIndex(tokenObj => tokenObj._id == token._id);
            state.tokens.splice(index, 1, token);
        },
        DELETE_TOKEN(state, _id){
            let index = state.tokens.findIndex(token => token._id == _id);
            state.tokens.splice(index, 1);
        }
    },
    getters: {
        GET_TOKENS(state){
            return state.tokens;
        }
    },
    actions: {
        
    }
}
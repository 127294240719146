
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        floras: []
    },
    mutations: {
        INDEX_FLORAS(state){
            Vue.prototype.$axios.get('/api/flora').then(response => {
              if(response.status == 200){
                state.floras = response.data;
              }else{
                console.log(response);
              }
            }).catch(error => {
              console.log(error);
            });
        },
        INSERT_FLORA(state, flora){
          state.floras.push(flora);
        },
        UPDATE_FLORA(state, flora){
          let index = state.floras.findIndex(floraObj => floraObj._id == flora._id);
          state.floras.splice(index, 1, flora);
        },
        DELETE_FLORA(state, _id){
          let index = state.floras.findIndex(floraObj => floraObj._id == _id);
          state.floras.splice(index, 1);
        }
    },
    getters: {
        GET_FLORAS(state){
            return state.floras;
        }
    },
    actions: {
  
    }
  }
  
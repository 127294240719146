import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from './snackbar'
import flora from './flora'
import semilla from './semilla'
import miel from './miel'
import artesania from './artesania'
import usuario from './usuario'
import token from './token'
import error from './error'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: null
  },
  mutations: {
    SET_SESSION(state, session){
      state.session = session;
    },
    DELETE_SESSION(state){
      state.session = null;
    },
    UPDATE_PROFILE(state, user){
      state.session.user = user;
    }
  },
  getters: {
    GET_SESSION(state){
      return state.session;
    }
  },
  actions: {
  },
  modules: {
    token: token,
    flora: flora,
    semilla: semilla,
    miel: miel,
    artesania: artesania,
    usuario: usuario,
    snackbar: snackbar,
    error: error
  },
  plugins: [createPersistedState()],
})

<template>
  <div id="error">
    <v-snackbar v-model="visible" top>
      <v-layout align-center>
        <v-layout column>
          <div>
            <strong class="red--text">Ocurrio un error</strong>
          </div>
          <div>{{error.message}}</div>
        </v-layout>
        <v-btn icon @click="$store.commit('error/HIDE_ERROR')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      </v-layout>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: "Error",
  data: () => ({
    visible: false,
  }),
  computed: {
    ...mapState(['error'])
  }
};
</script>
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        artesanias: []
    },
    mutations: {
        INDEX_ARTESANIAS(state){
            Vue.prototype.$axios.get('/api/artesania').then(response => {
                if(response.status == 200){
                  state.artesanias = response.data;
                }else{
                  console.log(response);
                }
              }).catch(error => {
                console.log(error);
              });
        },
        INSERT_ARTESANIA(state, artesania){
            state.artesanias.push(artesania);
        },
        UPDATE_ARTESANIA(state, artesania){
            let index = state.artesanias.findIndex(artesaniaObj => artesaniaObj._id == artesania._id);
            state.artesanias.splice(index, 1, artesania);
        },
        DELETE_ARTESANIA(state, _id){
            let index = state.artesanias.findIndex(artesaniaObj => artesaniaObj._id == _id);
            state.artesanias.splice(index, 1);
        }
    },
    getters: {
        GET_ARTESANIAS(state){
            return state.artesanias
        }
    },
    actions: {
        
    }
}
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        usuarios: []
    },
    mutations: {
        INDEX_USUARIOS(state){
            Vue.prototype.$axios.get('/api/user').then(response => {
                if(response.status == 200){
                    state.usuarios = response.data;
                }else{
                    console.log(response);
                }
            }).catch(error => {
                console.log(error);
            })
        },
        INSERT_USUARIO(state, user){
            state.usuarios.push(user);
        },
        UPDATE_USUARIO(state, user){
            let index = state.usuarios.findIndex(userObj => userObj._id == user._id);
            state.usuarios.splice(index, 1, user);
        },
        DELETE_USUARIO(state, user){
            let index = state.usuarios.findIndex(userObj => userObj._id == user._id);
            state.usuarios.splice(index, 1);
        }
    },
    getters: {
        GET_USUARIOS(state){
            return state.usuarios;
        }
    },
    actions: {
        
    }
}
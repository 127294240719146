import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/floras',
    component: () => import('../views/Floras.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'nuevo',
        name: 'new-floras',
        components: {
          floras: () => import('../components/flora/store.vue'),
        }
      },
      {
        path: ':id',
        name: 'edit-floras',
        components: {
          floras: () => import('../components/flora/edit.vue')
        }
      }
    ]
  },
  {
    path: '/semillas',
    component: () => import('../views/Semillas.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'nuevo',
        name: 'new-semillas',
        components: {
          semillas: () => import('../components/semilla/store.vue')
        }
      },
      {
        path: ':id',
        name: 'edit-semillas',
        components: {
          semillas: () => import('../components/semilla/edit.vue')
        }
      }
    ]
  },
  {
    path: '/mieles',
    component: () => import('../views/Mieles.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'nuevo',
        name: 'new-mieles',
        components: {
          mieles: () => import('../components/miel/store.vue')
        }
      },
      {
        path: ':id',
        name: 'edit-mieles',
        components: {
          mieles: () => import('../components/miel/edit.vue')
        }
      }
    ]
  },
  {
    path: '/artesanias',
    component: () => import('../views/Artesanias.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'nuevo',
        name: 'new-artesanias',
        components: {
          artesanias: () => import('../components/artesania/store.vue')
        }
      },
      {
        path: ':id',
        name: 'edit-artesanias',
        components: {
          artesanias: () => import('../components/artesania/edit.vue')
        }
      }
    ]
  },
  {
    path: '/usuarios',
    component: () => import('../views/Users.vue'),
    meta: { requiresAuth: true, requiresRole: ['ADMIN'] },
    children: [
      {
        path: 'nuevo',
        name: 'new-usuarios',
        components: {
          usuarios: () => import('../components/user/store.vue')
        },
        meta: { requiresAuth: true, requiresRole: ['ADMIN'] }
      },
      {
        path: ':id',
        name: 'edit-usuarios',
        components: {
          usuarios: () => import('../components/user/edit.vue')
        },
        meta: { requiresAuth: true, requiresRole: ['ADMIN'] }
      }
    ]
  },
  {
    path: '/configuracion',
    component: () => import('../views/Settings.vue'),
    meta: { requiresAuth: true, requiresRole: ['ADMIN'] },
    children: [
      {
        path: 'token/nuevo',
        name: 'new-token',
        components: {
          settings: () => import('../components/token/store.vue')
        },
        meta: { requiresAuth: true, requiresRole: ['ADMIN'] },
      },
      {
        path: 'token/:id',
        name: 'edit-token',
        components: {
          settings: () => import('../components/token/edit.vue')
         },
        meta: { requiresAuth: true, requiresRole: ['ADMIN'] },
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if(to.name != 'Login'){
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.GET_SESSION != null) {
        if(to.matched.some(record => record.meta.requiresRole)) {
          if(to.meta.requiresRole.find(permission => permission == store.getters.GET_SESSION.user.role) != undefined){
            next()
          }else{
            next('/')
          }
        }else{
          next()
        }
      }else{
        next('/login')
      }
    } else {
      next()
    }
  }
  if(to.name == 'Login'){
    if(store.getters.GET_SESSION != null){
      next('/');
    }else{
      next()
    }
  }
});

export default router

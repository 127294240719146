<template>
<div id="profile">
    <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-list-item @click="visible = true" active-class="primary--text secondary" v-bind="attrs" v-on="on">
                <v-list-item-icon>
                    <v-icon small class="mx-1">mdi-account-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Mi perfil</v-list-item-title>
            </v-list-item>
        </template>
        <span>Mi perfil</span>
    </v-tooltip>
    <v-dialog transition="dialog-top-transition" max-width="350" v-model="visible">
        <v-card :loading="loading" :disabled="loading">
            <v-toolbar class="elevation-custom-table">
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Mi perfil</span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="mb-2">
                        <v-text-field @input="$v.usuario.name.$touch()" @blur="$v.usuario.name.$touch()" :error-messages="nameErrors" v-model="usuario.name" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Nombre<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field @input="$v.usuario.email.$touch()" @blur="$v.usuario.email.$touch" :error-messages="emailErrors" v-model="usuario.email" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Correo electrónico<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field @input="$v.usuario.password.$touch()" @blur="$v.usuario.password.$touch" :error-messages="passwordErrors"  hint="Dejar en blanco si no se quiere actualizar." persistent-hint type="password" v-model.trim="usuario.password" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                <!-- Contraseña<span class="red--text"><strong> *</strong></span> -->
                                Contraseña<span class="red--text"></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2" v-if="usuario.password != null && usuario.password != ''">
                        <v-text-field @input="$v.usuario.passwordConfirm.$touch()" @blur="$v.usuario.passwordConfirm.$touch" :error-messages="passwordConfirmErrors" type="password" v-model.trim="usuario.passwordConfirm" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Confirmar contraseña<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text @click="visible = false" class="text-none">Cerrar</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="update" class="text-none" color="primary" :disabled="$v.usuario.$invalid">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { required, minLength, email, sameAs, requiredIf } from 'vuelidate/lib/validators';
export default {
    name: 'Profile',
    data: () => ({
        loading: false,
        visible: false,
        usuario: {
            _id: null,
            name: null,
            email: null,
            password: null,
            passwordConfirm: null,
        },
    }),
    methods: {
        async read() {
            this.loading = true;
            await this.$axios.get('/api/profile').then(response => {
                if (response.status == 200) {
                    this.usuario._id = response.data._id;
                    this.usuario.name = response.data.name;
                    this.usuario.email = response.data.email;
                    this.$v.$reset();
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            });
        },
        async update() {
            this.loading = true;
            await this.$axios.put('/api/profile', this.usuario).then(response => {
                if (response.status == 200) {
                    this.$store.commit('UPDATE_PROFILE', response.data);
                    this.$store.commit('usuario/UPDATE_USUARIO', response.data);
                    this.$v.$reset();
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            });
        }
    },
    watch: {
        visible() {
            this.read();
        },
        'usuario.password'() {
            if(this.usuario.password == ''){
                console.log('isnull')
                this.usuario.password = null;
            }
        },
        'usuario.passwordConfirm'() {
            if(this.usuario.passwordConfirm == ''){
                this.usuario.passwordConfirm = null;
            }
        }
    },
    computed: {
        nameErrors() {
            const errors = [];
            if (this.$v.usuario.name.$dirty){
                if(this.$v.usuario.name.requited){
                    errors.push('Este campo es obligatorio.')
                }
            }
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (this.$v.usuario.email.$dirty){
                if (!this.$v.usuario.email.required) {
                    errors.push('Este campo es obligatorio.')
                }
                if (!this.$v.usuario.email.email) {
                    errors.push('Este campo debe ser una direccción de correo electrónico valida.')
                }
            }
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if(this.$v.usuario.password.$dirty){
                if (!this.$v.usuario.password.minLength) {
                    errors.push('La contraseña debe tener al menos 8 caracteres.')
                }
            }
            return errors;
        },
        passwordConfirmErrors() {
            const errors = [];
            if(this.$v.usuario.passwordConfirm.$dirty){
                if(!this.$v.usuario.passwordConfirm.sameAsPassword){
                    errors.push('Los valores no coinciden.')
                }
            }
            return errors;
        }
    },
    validations: {
        usuario: {
            name: {
                required
            },
            email: {
                required,
                email
            },
            password: {
                required: requiredIf((usuario) => {
                    return usuario.password != null || usuario.password == '';
                }),
                minLength: minLength(8)
            },
            passwordConfirm: {
                sameAsPassword: sameAs('password')
            }
        }
    }
}
</script>

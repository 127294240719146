export default {
    namespaced: true,
    state: {
        visible: false,
        message: '',
    },
    getters: {
  
    },
    actions: {
  
    },
    mutations: {
      SHOW_ERROR(state, settings){
        state.visible = true;
        state.message = settings.message;
      },
      HIDE_ERROR(state){
        state.visible = false;
      }
    }
  }
  
<template>
<!-- <v-snackbar v-model="snackbar.visible" top>
    <v-icon class="mr-2" small>{{snackbar.icon}}</v-icon>
    {{snackbar.message}}
    <v-spacer></v-spacer>
    <template v-slot:action="{ attrs }">
        <v-btn color="white" text class="text-none" v-bind="attrs" @click="snackbar.visible = false">
            Cerrar
        </v-btn>
    </template>
</v-snackbar> -->
<v-snackbar v-model="snackbar.visible" top>
    <v-layout align-center>
        <v-layout column>
            <div>
                <strong class="white--text">Notificación</strong>
            </div>
            <div>{{snackbar.message}}</div>
        </v-layout>
        <v-btn icon @click="$store.commit('snackbar/HIDE_SNACKBAR')">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-layout>
</v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            show: false,
            top: true,
            message: "",
            color: "",
            timeout: 5000
        };
    },
    computed: {
        ...mapState(['snackbar'])
    },
    created() {

    }
};
</script>

export default {
  namespaced: true,
  state: {
      visible: false,
      message: '',
  },
  getters: {

  },
  actions: {

  },
  mutations: {
    SHOW_SNACKBAR(state, settings){
      state.visible = true;
      state.message = settings.message;
    },
    HIDE_SNACKBAR(state){
      state.visible = false;
    }
  }
}

import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        semillas: []
    },
    mutations: {
        INDEX_SEMILLAS(state){
            Vue.prototype.$axios.get('/api/semilla').then(response => {
                if(response.status == 200){
                    state.semillas = response.data;
                }else{
                    console.log(response);
                }
            }).catch(error => {
                console.log(error);
            });
        },
        INSERT_SEMILLA(state, semilla){
            state.semillas.push(semilla);
        },
        UPDATE_SEMILLA(state, semilla){
            let index = state.semillas.findIndex(semillaObj => semillaObj._id == semilla._id);
            state.semillas.splice(index, 1, semilla)
        },
        DELETE_SEMILLA(state, _id){
            let index = state.semillas.findIndex(semillaObj => semillaObj._id == _id);
            state.semillas.splice(index, 1);
        }
    },
    getters: {
        GET_SEMILLAS(state){
            return state.semillas;
        }
    },
    actions: {

    }
}